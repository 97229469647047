import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js/auto";
// import { pfinal, tfinal } from "./Container";

Chart.register(CategoryScale);

const Barchart = ({ pfinal, tfinal, divider }) => {
  const color = pfinal.map((value) => {
    if (value < 1) {
      return "#EF4444"; //red
    } else if (value >= 1 && value < 500) {
      return "#EF5350"; // yellow;
    } else {
      return "#60A5FA";
      // return "#1E2F97";
    }
  });

  // console.log(pfinal);
  // console.log(tfinal);
  return (
    <div className="bg-gray-100 py-7 px-0">
      {/* <h3 className="text-xl font-bold mb-4">Power Trend</h3> */}
      <div className="flex flex-col sm:flex-row justify-center items-center">
        <div className="w-full h-full sm:h-1/2 md:h-1/2 lg:h-1/2 xl:h-full sm:w-1/2 md:w-full lg:w-full xl:w-full">
          <Bar
            data={{
              labels: tfinal.map((t, i) => (i % divider === 0 ? t : "")),

              datasets: [
                {
                  label: "Power vs Time",
                  backgroundColor: color,
                  // hoverBackgroundColor: color.map(
                  //   (c) => `${c}D9` // lighter version of color for hover state
                  // ),

                  data: pfinal,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                x: {
                  type: "category",
                  offset: true,
                  ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90,
                    font: {
                      size: 14,
                      weight: "bold",
                    },
                  },
                  grid: {
                    display: false,
                    color: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderColor: "rgba(0,0, 0.1)",
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                    font: {
                      size: 14,
                      weight: "bold",
                    },
                  },
                  grid: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)",
                    borderWidth: 1,
                    borderColor: "rgba(0, 0, 0, 0.1)",
                  },
                },
              },
              plugins: {
                legend: {
                  display: true,
                  position: "bottom",
                  labels: {
                    font: {
                      size: 13,
                      weight: "bold",
                    },
                  },
                },
                title: {
                  display: false,
                  text: "Power Trend",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Barchart;
