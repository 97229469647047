import React, { useState } from "react";
import Barchart from "./Barchart";
import { Link } from "react-router-dom";

const F2sh1graphs = () => {
  const pfinal = JSON.parse(localStorage.getItem("pfinal"));

  const tfinal = JSON.parse(localStorage.getItem("tfinal"));

  let loop = pfinal.length;

  const [sh1hr1pow, setSh1hr1pow] = useState([]);
  const [sh1hr1time, setSh1hr1time] = useState([]);
  const [sh1hr2pow, setSh1hr2pow] = useState([]);
  const [sh1hr2time, setSh1hr2time] = useState([]);
  const [sh1hr3pow, setSh1hr3pow] = useState([]);
  const [sh1hr3time, setSh1hr3time] = useState([]);
  const [sh1hr4pow, setSh1hr4pow] = useState([]);
  const [sh1hr4time, setSh1hr4time] = useState([]);
  const [sh1hr5pow, setSh1hr5pow] = useState([]);
  const [sh1hr5time, setSh1hr5time] = useState([]);
  const [sh1hr6pow, setSh1hr6pow] = useState([]);
  const [sh1hr6time, setSh1hr6time] = useState([]);
  const [sh1hr7pow, setSh1hr7pow] = useState([]);
  const [sh1hr7time, setSh1hr7time] = useState([]);
  const [sh1hr8pow, setSh1hr8pow] = useState([]);
  const [sh1hr8time, setSh1hr8time] = useState([]);
  const [sh1hr9pow, setSh1hr9pow] = useState([]);
  const [sh1hr9time, setSh1hr9time] = useState([]);
  const [sh1hr10pow, setSh1hr10pow] = useState([]);
  const [sh1hr10time, setSh1hr10time] = useState([]);
  const [sh1hr11pow, setSh1hr11pow] = useState([]);
  const [sh1hr11time, setSh1hr11time] = useState([]);
  const [sh1hr12pow, setSh1hr12pow] = useState([]);
  const [sh1hr12time, setSh1hr12time] = useState([]);

  for (let i = 0; i < loop; i++) {
    //shift1 8-9 pm
    if (tfinal[i] >= "20:00:00" && tfinal[i] < "21:00:00") {
      sh1hr1time.push(tfinal[i]);
      sh1hr1pow.push(pfinal[i]);
    }

    //shift1 9-10pm
    if (tfinal[i] >= "21:00:00" && tfinal[i] < "22:00:00") {
      sh1hr2time.push(tfinal[i]);
      sh1hr2pow.push(pfinal[i]);
    }

    //shift1 10-11pm
    if (tfinal[i] >= "22:00:00" && tfinal[i] < "23:00:00") {
      sh1hr3time.push(tfinal[i]);
      sh1hr3pow.push(pfinal[i]);
    }

    //shift1 11-12pm
    if (tfinal[i] >= "23:00:00" && tfinal[i] < "24:00:00") {
      sh1hr4time.push(tfinal[i]);
      sh1hr4pow.push(pfinal[i]);
    }

    //shift1 12am-1am
    if (tfinal[i] >= "00:00:00" && tfinal[i] < "01:00:00") {
      sh1hr5time.push(tfinal[i]);
      sh1hr5pow.push(pfinal[i]);
    }

    //shift1 1-2am
    if (tfinal[i] >= "01:00:00" && tfinal[i] < "02:00:00") {
      sh1hr6time.push(tfinal[i]);
      sh1hr6pow.push(pfinal[i]);
    }

    //shift 2-3am
    if (tfinal[i] >= "02:00:00" && tfinal[i] < "03:00:00") {
      sh1hr7time.push(tfinal[i]);
      sh1hr7pow.push(pfinal[i]);
    }

    //shift1 3-4am
    if (tfinal[i] >= "03:00:00" && tfinal[i] < "04:00:00") {
      sh1hr8time.push(tfinal[i]);
      sh1hr8pow.push(pfinal[i]);
    }

    //shift1 4-5am
    if (tfinal[i] >= "04:00:00" && tfinal[i] < "05:00:00") {
      sh1hr9time.push(tfinal[i]);
      sh1hr9pow.push(pfinal[i]);
    }

    //shift1 5-6am
    if (tfinal[i] >= "05:00:00" && tfinal[i] < "06:00:00") {
      sh1hr10time.push(tfinal[i]);
      sh1hr10pow.push(pfinal[i]);
    }

    //shift1 6-7am
    if (tfinal[i] >= "06:00:00" && tfinal[i] < "07:00:00") {
      sh1hr11time.push(tfinal[i]);
      sh1hr11pow.push(pfinal[i]);
    }

    //shift1 7-8am
    if (tfinal[i] >= "07:00:00" && tfinal[i] < "08:00:00") {
      sh1hr12time.push(tfinal[i]);
      sh1hr12pow.push(pfinal[i]);
    }
  }

  return (
    <div className="mx-3">
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            20:00-21:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr1pow} tfinal={sh1hr1time} divider={4} />
          {/* <Graphs graphData={graphData} /> */}
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            21:00-22:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr2pow} tfinal={sh1hr2time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            22:00-23:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr3pow} tfinal={sh1hr3time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            23:00-00:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr4pow} tfinal={sh1hr4time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            00:00-01:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr5pow} tfinal={sh1hr5time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            01:00-02:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr6pow} tfinal={sh1hr6time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            02:00-03:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr7pow} tfinal={sh1hr7time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            03:00-04:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr8pow} tfinal={sh1hr8time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            04:00-05:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr9pow} tfinal={sh1hr9time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            05:00-06:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr10pow} tfinal={sh1hr10time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            06:00-07:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr11pow} tfinal={sh1hr11time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            07:00-08:00 SHIFT-I
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={sh1hr12pow} tfinal={sh1hr12time} divider={4} />
        </div>
      </div>
      <div className="flex flex-col flex-wrap justify-center">
        <div className="flex justify-center">
          <h2 className="w-40 text-center bg-yellow-300 p-2 my-1 ">
            20:00-8:00 SHIFT-I Clustured Data
          </h2>
        </div>
        <div className="w-full h-1/2">
          <Barchart pfinal={pfinal} tfinal={tfinal} divider={60} />
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center">
        <Link to="/F2sh1container.js">
          <button className="bg-blue-400 rounded-lg w-40 p-3 m-2">
            Go Back
          </button>
        </Link>
      </div>
    </div>
  );
};

export default F2sh1graphs;
